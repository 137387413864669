import { default as _91slug_93TcfAkLMn61Meta } from "/home/forge/www.safiroptik.com.tr/pages/[slug].vue?macro=true";
import { default as addresstI7LHMSX2YMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/address.vue?macro=true";
import { default as _91id_93Wk5uni8iGhMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/orders/[id].vue?macro=true";
import { default as _91id_93aMW015usGHMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/orders/cancel/[id].vue?macro=true";
import { default as indexATDTSeIepFMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/orders/index.vue?macro=true";
import { default as reviewiEvyfznkTaMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/review.vue?macro=true";
import { default as _91id_932196Eo3pCYMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/statement/detail/[id].vue?macro=true";
import { default as indexKDOEUIvp60Meta } from "/home/forge/www.safiroptik.com.tr/pages/account/statement/index.vue?macro=true";
import { default as userikmM9ZwckkMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/user.vue?macro=true";
import { default as wishlistGsZT1uYqJXMeta } from "/home/forge/www.safiroptik.com.tr/pages/account/wishlist.vue?macro=true";
import { default as cartVI7XZb9kIOMeta } from "/home/forge/www.safiroptik.com.tr/pages/cart.vue?macro=true";
import { default as index9TNLvLTl7cMeta } from "/home/forge/www.safiroptik.com.tr/pages/checkout/index.vue?macro=true";
import { default as payment1hfHLe8QH4Meta } from "/home/forge/www.safiroptik.com.tr/pages/checkout/payment.vue?macro=true";
import { default as summary_46clientNDlxuQURc3Meta } from "/home/forge/www.safiroptik.com.tr/pages/checkout/summary.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/home/forge/www.safiroptik.com.tr/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as requestesLLkv3R0EMeta } from "/home/forge/www.safiroptik.com.tr/pages/customer/request.vue?macro=true";
import { default as successetF5HREb2ZMeta } from "/home/forge/www.safiroptik.com.tr/pages/customer/success.vue?macro=true";
import { default as iletisim1bTrHlTPp9Meta } from "/home/forge/www.safiroptik.com.tr/pages/iletisim.vue?macro=true";
import { default as index4uYQyTEM5XMeta } from "/home/forge/www.safiroptik.com.tr/pages/index.vue?macro=true";
import { default as loginaRiF8JPkd0Meta } from "/home/forge/www.safiroptik.com.tr/pages/login.vue?macro=true";
import { default as offerS5AVjZSehoMeta } from "/home/forge/www.safiroptik.com.tr/pages/offer.vue?macro=true";
import { default as statusMWnsHiudy5Meta } from "/home/forge/www.safiroptik.com.tr/pages/order/status.vue?macro=true";
import { default as trackFMNagiorZZMeta } from "/home/forge/www.safiroptik.com.tr/pages/order/track.vue?macro=true";
import { default as forgottett7n1oaxMeta } from "/home/forge/www.safiroptik.com.tr/pages/password/forgot.vue?macro=true";
import { default as resetY1NHW6fk5LMeta } from "/home/forge/www.safiroptik.com.tr/pages/password/reset.vue?macro=true";
import { default as payment_45notificationK3dtjSstwAMeta } from "/home/forge/www.safiroptik.com.tr/pages/payment-notification.vue?macro=true";
import { default as summaryPPiTjbAP5OMeta } from "/home/forge/www.safiroptik.com.tr/pages/payment/summary.vue?macro=true";
import { default as registerdleRiBfvAbMeta } from "/home/forge/www.safiroptik.com.tr/pages/register.vue?macro=true";
import { default as search81dNQ0TFx6Meta } from "/home/forge/www.safiroptik.com.tr/pages/search.vue?macro=true";
import { default as locationsKbKUYf8YqVMeta } from "/home/forge/www.safiroptik.com.tr/modules/locationsPage/pages/locations.vue?macro=true";
import { default as component_45stub6Itg2UR9R5Meta } from "/home/forge/www.safiroptik.com.tr/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub6Itg2UR9R5 } from "/home/forge/www.safiroptik.com.tr/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug___tr",
    path: "/:slug()",
    meta: _91slug_93TcfAkLMn61Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/[slug].vue")
  },
  {
    name: "account-address___tr",
    path: "/account/address",
    meta: addresstI7LHMSX2YMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/address.vue")
  },
  {
    name: "account-orders-id___tr",
    path: "/account/orders/:id()",
    meta: _91id_93Wk5uni8iGhMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/orders/[id].vue")
  },
  {
    name: "account-orders-cancel-id___tr",
    path: "/account/orders/cancel/:id()",
    meta: _91id_93aMW015usGHMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/orders/cancel/[id].vue")
  },
  {
    name: "account-orders___tr",
    path: "/account/orders",
    meta: indexATDTSeIepFMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/orders/index.vue")
  },
  {
    name: "account-review___tr",
    path: "/account/review",
    meta: reviewiEvyfznkTaMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/review.vue")
  },
  {
    name: "account-statement-detail-id___tr",
    path: "/account/statement/detail/:id()",
    meta: _91id_932196Eo3pCYMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/statement/detail/[id].vue")
  },
  {
    name: "account-statement___tr",
    path: "/account/statement",
    meta: indexKDOEUIvp60Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/statement/index.vue")
  },
  {
    name: "account-user___tr",
    path: "/account/user",
    meta: userikmM9ZwckkMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/user.vue")
  },
  {
    name: "account-wishlist___tr",
    path: "/account/wishlist",
    meta: wishlistGsZT1uYqJXMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/account/wishlist.vue")
  },
  {
    name: "cart___tr",
    path: "/cart",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/cart.vue")
  },
  {
    name: "checkout___tr",
    path: "/checkout",
    meta: index9TNLvLTl7cMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/checkout/index.vue")
  },
  {
    name: "checkout-payment___tr",
    path: "/checkout/payment",
    meta: payment1hfHLe8QH4Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/checkout/payment.vue")
  },
  {
    name: "checkout-summary___tr",
    path: "/checkout/summary",
    component: () => createClientPage(() => import("/home/forge/www.safiroptik.com.tr/pages/checkout/summary.client.vue"))
  },
  {
    name: "customer-request___tr",
    path: "/customer/request",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/customer/request.vue")
  },
  {
    name: "customer-success___tr",
    path: "/customer/success",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/customer/success.vue")
  },
  {
    name: "iletisim___tr",
    path: "/iletisim",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/iletisim.vue")
  },
  {
    name: "index___tr",
    path: "/",
    meta: index4uYQyTEM5XMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/index.vue")
  },
  {
    name: "login___tr",
    path: "/login",
    meta: loginaRiF8JPkd0Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/login.vue")
  },
  {
    name: "offer___tr",
    path: "/offer",
    meta: offerS5AVjZSehoMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/offer.vue")
  },
  {
    name: "order-status___tr",
    path: "/order/status",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/order/status.vue")
  },
  {
    name: "order-track___tr",
    path: "/order/track",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/order/track.vue")
  },
  {
    name: "password-forgot___tr",
    path: "/password/forgot",
    meta: forgottett7n1oaxMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/password/forgot.vue")
  },
  {
    name: "password-reset___tr",
    path: "/password/reset",
    meta: resetY1NHW6fk5LMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/password/reset.vue")
  },
  {
    name: "payment-notification___tr",
    path: "/payment-notification",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/payment-notification.vue")
  },
  {
    name: "payment-summary___tr",
    path: "/payment/summary",
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/payment/summary.vue")
  },
  {
    name: "register___tr",
    path: "/register",
    meta: registerdleRiBfvAbMeta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/register.vue")
  },
  {
    name: "search___tr",
    path: "/search",
    meta: search81dNQ0TFx6Meta || {},
    component: () => import("/home/forge/www.safiroptik.com.tr/pages/search.vue")
  },
  {
    name: "contact___tr",
    path: "/magazalarimiz",
    component: () => import("/home/forge/www.safiroptik.com.tr/modules/locationsPage/pages/locations.vue")
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5503-c4152-seffaf-gul-kurusu",
    component: component_45stub6Itg2UR9R5
  },
  {
    name: component_45stub6Itg2UR9R5Meta?.name,
    path: "/5005-c0707-lacivert",
    component: component_45stub6Itg2UR9R5
  }
]